import * as React from "react"
import * as styles from "./apps.module.css"

const Apps = ({apps, storeType}) => {
    const createLink = (app) => {
        if (storeType === "googleplay") {
            return `https://play.google.com/store/apps/details?id=${app.store.googleplay}`
        } else {
            return `https://itunes.apple.com/jp/app/itunes-u/id${app.store.appstore}`
        }
    }
    return (
        <ul>{
            apps.map((app) => {
                return (
                    <li key={app.id} className={styles.li}>
                        <a href={createLink(app)}
                           className={styles.a}
                           target="_blank"
                           rel="noreferrer">
                            <p className={styles.appName}>{app.name}</p>
                            <div className={styles.lower}>
                                <picture>
                                    <source
                                        srcSet={`https://storage.googleapis.com/nullhouse-info/apps/${app.image}.webp`}
                                        type="image/webp"/>
                                    <img src={`https://storage.googleapis.com/nullhouse-info/apps/${app.image}.png`}
                                         alt={app.name}
                                         className={styles.appIcon}
                                    />
                                </picture>
                                <p className={styles.appDescription}
                                   dangerouslySetInnerHTML={{__html: app.description.replace(/\n/gm, '<br />')}}/>
                            </div>
                        </a>
                    </li>
                )
            })
        }
        </ul>
    )
}

export default Apps
